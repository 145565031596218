import React, { useState, useEffect, useCallback } from 'react';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';

import { graphql, Link } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import SEO from 'src/components/SEO';
import WorkoutCategories from 'src/components/workout-library/WorkoutCategories';
import WorkoutFilters from 'src/components/workout-library/WorkoutFilters';
import SearchResults from 'src/components/workout-library/SearchResults';

import { CardCarousel, WorkoutCard, StartTrial, FloatingTryForFree } from 'src/components/shared';
import { Button } from 'semantic-ui-react';
import { BorderBlockWithButton } from 'src/components/ui';
import Layout from 'src/components/layout/Layout';

import * as Styles from 'src/styles/workouts-page.module.scss';
import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import Loader from 'src/components/shared/Loader';
import classNames from 'classnames';
import Colors from 'src/utils/colors';
import RedirectToZelda from 'src/components/redirect-to-zelda';
interface WorkoutsIndexProps {
    data: any; // help?
}

const Index = ({ data }: WorkoutsIndexProps) => {
    return <RedirectToZelda />
    
    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.lg, Directions.down);
    const [expandSection, setExpandSection] = useState(false);
    const [loadingNewWorkouts, setloadingNewWorkouts] = useState(true);
    const workoutCategories = useSelector(
        PvolveSelectors.workoutCategories.workoutCategoriesResolve
    );

    const completeFilteredResults = useSelector(PvolveSelectors.content.completeFilteredResults);
    const workoutFilter = useSelector(PvolveSelectors.content.workoutFilter);
    const entitled = useSelector(Selectors.auth.entitled);
    const targetedNewWorkouts = useSelector(Selectors.workouts.getTargetedNewWorkouts);
    const userCohort = useSelector(Selectors.account.userCohort);

    const dispatch = useDispatch();

    const { allContentfulWorkoutCategory } = data;

    useEffect(() => {
        if (!isEmpty(targetedNewWorkouts)) {
            setloadingNewWorkouts(false);
        }
    }, [targetedNewWorkouts]);

    useEffect(() => {
        dispatch(Actions.workouts.loadTargetedNewWorkouts.trigger());
        dispatch(Actions.workoutCategories.loadWorkoutCategoriesContent.trigger());
    }, [userCohort, dispatch]);

    const {
        results: workoutResults,
        resultsWithAdditionalEquipment: workoutAdditionalResults,
        resultsWithEquipmentOnly: workoutEquipmentResults,
        wasFilteredByEquipment,
    } = completeFilteredResults;

    const filterCount = Object.values(workoutFilter).length;

    const WorkoutCardWithPlayer = ({ data: workout }) => <WorkoutCard workout={workout} />;
    const [drawerVisible, setDrawerVisible] = useState(false);
    const toggleDrawer = (direction: boolean) => () => {
        setDrawerVisible(direction);
    };
    const pageContext = {};

    const toggleAllCategories = useCallback(() => {
        setExpandSection(!expandSection);
    }, [expandSection, setExpandSection]);

    const renderCategories = useCallback(() => {
        const resolveCategory = (unresolvedCategoryList: any[]) => {
            let outputList: any[] = [];
            unresolvedCategoryList.forEach((category) => {
                const resolvedCategory = allContentfulWorkoutCategory.edges.filter(
                    (i) => i?.node?.slug == category?.slug
                )[0];
                const categoryResolved = {
                    ...category,
                    image: resolvedCategory?.node?.image,
                    workouts: resolvedCategory?.node?.workouts,
                };
                outputList.push(categoryResolved);
            });

            return outputList;
        };

        if (!isEmpty(workoutCategories?.targetedCategories)) {
            return (
                <>
                    <WorkoutCategories
                        workoutCategories={resolveCategory(workoutCategories?.targetedCategories)}
                        title="for you"
                    />
                    <div className={Styles.buttonWrapper}>
                        <Button size="large" secondary onClick={() => toggleAllCategories()}>
                            {expandSection ? 'SEE LESS' : 'SEE MORE'}
                        </Button>
                    </div>
                    {expandSection && (
                        <WorkoutCategories
                            workoutCategories={resolveCategory(
                                workoutCategories?.defaultCategories
                            )}
                            title="all categories"
                        />
                    )}
                </>
            );
        }
        if (!isEmpty(workoutCategories?.defaultCategories)) {
            return (
                <WorkoutCategories
                    workoutCategories={resolveCategory(workoutCategories?.defaultCategories)}
                    title="all categories"
                />
            );
        }
        return null;
    }, [workoutCategories, allContentfulWorkoutCategory, expandSection, toggleAllCategories]);

    return (
        <Layout pageContext={pageContext}>
            <div className={Styles.workoutsContainer}>
                <SEO title="Workout Library" />
                <Grid>
                    {loadingNewWorkouts ? (
                        <Loader
                            spinnerColor={Colors.black}
                            className="padding-top--large margin-bottom--large"
                        />
                    ) : (
                        !isEmpty(targetedNewWorkouts) && (
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="padding-horizontal--small">
                                        <CardCarousel
                                            title={'New Workouts'}
                                            to="/workouts/new"
                                            data={targetedNewWorkouts}
                                            renderItem={WorkoutCardWithPlayer}
                                        />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        )
                    )}
                    <Grid.Row className="padding-horizontal--small">
                        <Grid.Column>
                            <WorkoutFilters
                                count={workoutResults?.length}
                                drawerVisible={drawerVisible}
                                drawerOn={toggleDrawer(true)}
                                drawerOff={toggleDrawer(false)}
                                Styles={Styles}
                                isHiddenCounter={wasFilteredByEquipment}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            {filterCount > 0 ? (
                                isEmpty(workoutResults) ? null : wasFilteredByEquipment ? (
                                    <>
                                        {!!workoutEquipmentResults &&
                                            workoutEquipmentResults?.length > 0 && (
                                            <SearchResults
                                                workoutResults={workoutEquipmentResults}
                                                title="Features selected equipment only"
                                            />
                                        )}
                                        {!!workoutAdditionalResults &&
                                            workoutAdditionalResults?.length > 0 && (
                                            <SearchResults
                                                workoutResults={workoutAdditionalResults}
                                                title="FEATURES selected & ADDITIONAL equipment"
                                            />
                                        )}
                                    </>
                                ) : (
                                    <SearchResults workoutResults={workoutResults} />
                                )
                            ) : (
                                renderCategories()
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {entitled && (
                    <div
                        className={classNames(
                            Styles.workoutsCtaButton,
                            `margin-vertical--large padding-horizontal--small`
                        )}
                    >
                        <BorderBlockWithButton
                            alignment={isTabletPortraitOrBelow ? 'top' : 'left'}
                            iconName="pv-calendar-outline"
                            iconSize={42}
                            buttonProps={{
                                as: Link,
                                target: '_blank',
                                size: 'big',
                                to: '/series',
                                children: <>Find a series</>,
                            }}
                        >
                            <h3 className="bold upper">Set up strong with a series</h3>
                            <div className="p1">
                                Browse our library of programs designed with your goals in mind
                            </div>
                        </BorderBlockWithButton>
                    </div>
                )}
                <StartTrial />
                {!entitled && <FloatingTryForFree />}
            </div>
        </Layout>
    );
};

export const query = graphql`
    query workoutsCategoriesQuery {
        allContentfulWorkoutCategory(filter: { hidden: { ne: true } }, sort: { fields: order }) {
            edges {
                node {
                    name
                    slug
                    tileTextColor
                    tileTextBgColor
                    workouts {
                        contentful_id
                    }
                    image {
                        fixed(width: 200, height: 200) {
                            ...GatsbyContentfulFixed_withWebp
                        }
                        fluid {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;
export default Index;
